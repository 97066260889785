import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";

const NewsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Rosamund" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpArticle(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					articleFields {
						description
						url
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "News",
				item: {
					url: `${siteUrl}/about-us/news`,
					id: `${siteUrl}/about-us/news`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="News | The Ella Roberta Foundation"
				description="News page The Ella Roberta Foundation."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/news`,
					title: "News | The Ella Roberta Foundation",
					description: "News page The Ella Roberta Foundation.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-white">
					<Hero
						title={<span>NEWS</span>}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="py-5 pt-lg-8">
						<Container>
							{data.allWpArticle.nodes.map((campaign) => (
								<Row className="gx-lg-6 mb-8 align-items-center">
									<Col lg={7}>
										<h2 className="mb-4">{campaign.title}</h2>
										<p>{campaign.articleFields.description}</p>
										<Button
											className="fs-5 w-100 w-md-auto px-4 mt-4 py-2"
											variant="outline-black"
											target="_blank"
											rel="noreferrer"
											href={campaign.articleFields.url}
										>
											READ MORE
										</Button>
									</Col>
									<Col
										className="mb-5 mb-lg-0"
										xs={{ span: 12, order: "first" }}
										lg={{ span: 5, order: "first" }}
									>
										<GatsbyImage
											className="w-100 "
											image={
												campaign.articleFields.featuredImage.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={campaign.articleFields.featuredImage.altText}
										/>
									</Col>
								</Row>
							))}
						</Container>
					</section>

					<NewsletterBanner />
				</div>
			</Layout>
		</>
	);
};

export default NewsPage;
